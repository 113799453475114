import { QueryClient, useQuery } from '@tanstack/react-query';
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import {
  DeserializedStorePage,
  getChains,
  getSearchStoreApps,
  getStoreAppListByCategory,
  GetStoreAppListByCategorySearchParams,
  getStoreAppsPreview,
  getStoreChains,
  getStorePage,
  GetStorePageDataParams,
  StoreSearchAppsSearchParams,
} from '@shared/api';

const storeQueryKeys = {
  storePage: (params: GetStorePageDataParams) => ['storePage', params],
  appListByCategory: (searchParams: GetStoreAppListByCategorySearchParams) => [
    'storeAppsByCategory',
    searchParams,
  ],
};

export const prefetchStorePageQuery = async (
  clientQuery: QueryClient,
  options: Parameters<typeof getStorePage>[0],
) => {
  await clientQuery.prefetchQuery({
    queryKey: storeQueryKeys.storePage(options.searchParams),
    queryFn: () => {
      return getStorePage({
        ...options,
        searchParams: {
          page: options.searchParams.page,
          ...(options.searchParams.chain && { chain: options.searchParams.chain }),
        },
      });
    },
  });

  return clientQuery.getQueryData<DeserializedStorePage>(
    storeQueryKeys.storePage(options.searchParams),
  );
};

export const useStoreDataQuery = ({ page, chain }: GetStorePageDataParams) => {
  return useQuery({
    queryKey: storeQueryKeys.storePage({ page, chain }),
    queryFn: () => getStorePage({ searchParams: { page, ...(chain && { chain }) } }),
  });
};

export const useStoreAppsPreview = (
  { search }: { search?: string } = {},
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getStoreAppsPreview>>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'placeholderData'
  >,
) => {
  return useQuery({
    queryKey: ['storeAppsPreview', search],
    queryFn: () =>
      getStoreAppsPreview({
        searchParams: {
          ...(search && { find: search }),
        },
      }),
    ...options,
  });
};

export const defaultStoreSearchAppsSearchParams: StoreSearchAppsSearchParams = {
  limit: 30,
};

const normalizeSearchParamsForStoreSearchApps = (params?: StoreSearchAppsSearchParams) => {
  const { find, limit, offset, category, subcategory, order, tags } = {
    ...defaultStoreSearchAppsSearchParams,
    ...params,
  };

  return {
    ...(limit && { limit }),
    ...(find && { find }),
    ...(offset && { offset }),
    ...(category && { category }),
    ...(subcategory && { subcategory }),
    ...(order && { order }),
    ...(tags && { tags }),
  };
};

export const prefetchStoreSearchAppsQuery = (
  clientQuery: QueryClient,
  params?: StoreSearchAppsSearchParams,
) => {
  const searchParams = normalizeSearchParamsForStoreSearchApps(params);

  return clientQuery.prefetchQuery({
    queryKey: ['storeSearchApps', searchParams],
    queryFn: () => {
      return getSearchStoreApps({
        searchParams,
      });
    },
  });
};

export const prefetchStoreChainsQuery = (clientQuery: QueryClient) => {
  return clientQuery.prefetchQuery({
    queryKey: ['storeChains'],
    queryFn: () => getStoreChains(),
  });
};

export const prefetchChainsQuery = (clientQuery: QueryClient) => {
  return clientQuery.prefetchQuery({
    queryKey: ['chains'],
    queryFn: () => getChains(),
  });
};

export const useStoreSearchAppsQuery = (
  params?: StoreSearchAppsSearchParams,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getSearchStoreApps>>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'placeholderData'
  >,
) => {
  const searchParams = normalizeSearchParamsForStoreSearchApps(params);
  return useQuery({
    queryKey: ['storeSearchApps', searchParams],
    queryFn: () =>
      getSearchStoreApps({
        searchParams,
      }),
    ...options,
  });
};

export const useStoreChainsQuery = (options?: { enabled: boolean }) => {
  return useQuery({
    queryKey: ['storeChains'],
    queryFn: () => getStoreChains(),
    ...options,
  });
};

export const useChainsQuery = (options?: { enabled: boolean }) => {
  return useQuery({
    queryKey: ['chains'],
    queryFn: () => getChains(),
    ...options,
  });
};

export const useStoreAppListByCategoryQuery = (
  searchParams: GetStoreAppListByCategorySearchParams,
) => {
  return useQuery({
    queryKey: storeQueryKeys.appListByCategory(searchParams),
    queryFn: () => getStoreAppListByCategory({ searchParams }),
  });
};

export const prefetchStoreAppListByCategoryQuery = (
  clientQuery: QueryClient,
  options: Parameters<typeof getStoreAppListByCategory>[0],
) => {
  return clientQuery.prefetchQuery({
    queryKey: storeQueryKeys.appListByCategory(options.searchParams),
    queryFn: () => {
      return getStoreAppListByCategory(options);
    },
  });
};
