import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import {
  depositLaunchpadFcfs,
  depositLaunchpadProRata,
  depositLaunchpadProRataSqrpGated,
  depositLaunchpadSqrp,
  depositLaunchpadSqrpGated,
  getLaunchpadProjects,
  getLaunchpadProjectWithSales,
  getLaunchpadSale,
  getLaunchpadSales,
  getSelfLaunchpadProjectWithSales,
} from '@api';

import { launchpadQueryKeys } from '@shared/hooks/query/launchpad';

import { AUTH_USER_QUERY_KEY } from './keys';

export const DEFAULT_LIMIT_LAUNCHPAD_PROJECTS = 4;

export const launchpadProjectKey = {
  list: (params: Required<Parameters<typeof getLaunchpadProjects>>[0]['searchParams']) => [
    'listLaunchpadProjects',
    params,
  ],
  infiniteList: (limit = 0, offset = 0, status = '', earlyAccess = false) => [
    'infiniteListLaunchpadProject',
    limit,
    offset,
    status,
    earlyAccess,
  ],
  project: (id: string, isAuth: boolean) => [
    'launchpadProject',
    id,
    isAuth ? AUTH_USER_QUERY_KEY : '',
  ],
  projectPrefetch: (id: string) => ['prefetchLaunchpadProject', id],
  sales: (id: string) => ['launchpadSales', id],
  sale: (id: string) => ['launchpadSale', id],
};

export const useLaunchpadProjectsListInfiniteQuery = (
  params: Parameters<typeof getLaunchpadProjects>[0],
) => {
  return useInfiniteQuery({
    queryKey: launchpadProjectKey.infiniteList(
      params?.searchParams?.limit || DEFAULT_LIMIT_LAUNCHPAD_PROJECTS,
      params?.searchParams?.offset || 0,
      params?.searchParams?.status,
      params?.searchParams?.earlyAccess,
    ),
    queryFn: ({ pageParam: offset }) =>
      getLaunchpadProjects({
        searchParams: {
          limit: params?.searchParams?.limit || DEFAULT_LIMIT_LAUNCHPAD_PROJECTS,
          offset,
          ...(params?.searchParams?.status ? { status: params?.searchParams?.status } : {}),
          earlyAccess: Boolean(params?.searchParams?.earlyAccess),
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage?.length === DEFAULT_LIMIT_LAUNCHPAD_PROJECTS
        ? pages.length * DEFAULT_LIMIT_LAUNCHPAD_PROJECTS
        : undefined,
    staleTime: 0,
  });
};

export const useLaunchpadProjectsListQuery = (
  params?: Parameters<typeof getLaunchpadProjects>[0],
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getLaunchpadProjects>>,
      unknown,
      unknown,
      any
    >,
    'placeholderData'
  >,
) => {
  return useQuery({
    queryKey: launchpadProjectKey.list(params?.searchParams),
    queryFn: () => getLaunchpadProjects(params),
    staleTime: 0,
    ...options,
  });
};

export const useLaunchpadProjectWithSalesQuery = (id: string, isAuth = false) => {
  return useQuery({
    queryKey: launchpadProjectKey.project(id, isAuth),
    queryFn: () =>
      isAuth ? getSelfLaunchpadProjectWithSales(id) : getLaunchpadProjectWithSales(id),
    staleTime: 0,
  });
};

export const useLaunchpadProjectWithSalesPrefetchQuery = (id: string) => {
  return useQuery({
    queryKey: launchpadProjectKey.projectPrefetch(id),
    queryFn: () => getLaunchpadProjectWithSales(id),
  });
};

export const prefetchLaunchpadProjectWithSalesQuery = (clientQuery: QueryClient, id: string) => {
  return clientQuery.prefetchQuery({
    queryKey: launchpadProjectKey.projectPrefetch(id),
    queryFn: () => getLaunchpadProjectWithSales(id),
  });
};

export const useLaunchpadSaleQuery = (id: string) => {
  return useQuery({
    queryKey: launchpadProjectKey.sale(id),
    queryFn: () => getLaunchpadSale(id),
    staleTime: 0,
  });
};

export const useLaunchpadSalesQuery = (id: string) => {
  return useQuery({
    queryKey: launchpadProjectKey.sales(id),
    queryFn: () => getLaunchpadSales(id),
    staleTime: 0,
  });
};

export const useLaunchpadDepositFcfsMutation = () => {
  return useMutation({
    mutationFn: ({ saleId, amount }: { saleId: string; amount: number }) =>
      depositLaunchpadFcfs(saleId, amount),
  });
};

export const useLaunchpadDepositProRataMutation = () => {
  return useMutation({
    mutationFn: ({
      saleId,
      amount,
      boosted,
    }: {
      saleId: string;
      amount: number;
      boosted: boolean;
    }) => depositLaunchpadProRata(saleId, amount, boosted),
  });
};

export const useLaunchpadDepositProRataSqrpGatedMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      saleId,
      amount,
      boosted,
    }: {
      saleId: string;
      amount: number;
      boosted: boolean;
    }) => {
      return depositLaunchpadProRataSqrpGated(saleId, amount, boosted);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [launchpadQueryKeys.getLaunchpadCurrentUser] });
    },
  });
};

export const useLaunchpadDepositSqrpGatedMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ saleId, amount }: { saleId: string; amount: number }) => {
      return depositLaunchpadSqrpGated(saleId, amount);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [launchpadQueryKeys.getLaunchpadCurrentUser] });
    },
  });
};

export const useLaunchpadDepositSQRpMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ saleId, amount }: { saleId: string; amount: number }) => {
      return depositLaunchpadSqrp(saleId, amount);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [launchpadQueryKeys.getLaunchpadCurrentUser] });
    },
  });
};
