import {
  FetchInfiniteQueryOptions,
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import {
  addAppLike,
  addAppReview,
  addAppSuggestion,
  addTrustReview,
  AppValidation,
  DEFAULT_APP_VALIDATION_VOTERS_LIMIT,
  DeserializedAppCurrentUserMeta,
  DeserializedAppData,
  DeserializedAppReview,
  DeserializedAppSuggestion,
  DeserializedAppTrustReview,
  DeserializedUser,
  editApp,
  getApp,
  getAppCurrentUserMeta,
  getAppForLIsting,
  getAppReviews,
  GetAppReviewsSearchParams,
  getAppValidation,
  likeReview,
  listingApp,
  ListingData,
  removeAppLike,
  ReviewVote,
  voteForReview,
} from '@api';

import { ApiOptions } from '@shared/api/ApiProvider';
import { ReviewType } from '@shared/api/types/review';

import { AUTH_USER_QUERY_KEY } from './keys';
import { AUTH_USER_INVALIDATION_QUERY_KEY } from './user';

export const appQueryKeys = {
  // AUTH_USER_INVALIDATION_QUERY_KEY is used because review can have user related data,
  // like userVote, userFunnyVote and we should revalidate it when user login
  app: (appId: string) => [AUTH_USER_INVALIDATION_QUERY_KEY, 'app', appId],
  appValidation: (appId: string) => ['appValidation', appId],
  appListing: (id: string) => ['appListing', id],
  appCurrentUserMeta: (appId: string) =>
    [AUTH_USER_QUERY_KEY, 'appCurrentUserMeta', appId] as const,
  // AUTH_USER_INVALIDATION_QUERY_KEY is used because review can have user related data,
  // like userVote, userFunnyVote and we should revalidate it when user login
  appInfiniteReviewList: (params: GetAppReviewsSearchParams) =>
    [AUTH_USER_INVALIDATION_QUERY_KEY, 'appInfiniteReviewList', params] as const,
  appValidationInfiniteVoterList: (appId: string) => ['appValidationInfiniteVoterList', appId],
};

export const useAppQuery = (
  appId: string,
  options?: Pick<
    UndefinedInitialDataOptions<Awaited<ReturnType<typeof getApp>>, unknown, unknown, any>,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: appQueryKeys.app(appId),
    queryFn: () => getApp({ searchParams: { appId } }),
    ...options,
  });
};

export const useAppListingQuery = (
  id: string,
  queryOptions?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getAppForLIsting>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: appQueryKeys.appListing(id),
    queryFn: () => getAppForLIsting(id),
    ...queryOptions,
  });
};

export const prefetchAppListingQuery = (
  clientQuery: QueryClient,
  appId: string,
  options?: ApiOptions<{ headers: { Cookie: string } }>,
) => {
  return clientQuery.prefetchQuery({
    queryKey: appQueryKeys.appListing(appId),
    queryFn: () => getAppForLIsting(appId, options),
  });
};

export const prefetchAppQuery = async (
  clientQuery: QueryClient,
  options: Parameters<typeof getApp>[0],
) => {
  const queryKey = appQueryKeys.app(options.searchParams.appId);

  await clientQuery.prefetchQuery({ queryKey, queryFn: () => getApp(options) });

  return clientQuery.getQueryData<ReturnType<typeof getApp>>(queryKey);
};

export const useAppValidationQuery = (
  appId: string,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getAppValidation>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: appQueryKeys.appValidation(appId),
    queryFn: () => getAppValidation(appId),
    ...options,
  });
};

export const prefetchAppValidationQuery = async (
  queryClient: QueryClient,
  appId: string,
  options?: Parameters<typeof getAppValidation>[1],
) => {
  const queryKey = appQueryKeys.appValidation(appId);

  await queryClient.prefetchQuery({
    queryKey,
    queryFn: () => getAppValidation(appId, options),
    retry: false,
  });

  const data = queryClient.getQueryData<AppValidation>(queryKey);

  if (data) {
    queryClient.setQueryData(
      appQueryKeys.appValidationInfiniteVoterList(appId),
      (old?: InfiniteData<AppValidation>) => {
        if (!old) {
          return {
            pageParams: [0],
            pages: [data],
          };
        }

        const [firstPage, ...otherPages] = old.pages;

        return {
          ...old,
          pages: firstPage ? [...otherPages, data] : [data],
        };
      },
    );
  }

  return;
};

export const useAppValidationVotersListInfiniteQuery = ({ id }: { id: string }) => {
  return useInfiniteQuery({
    queryKey: appQueryKeys.appValidationInfiniteVoterList(id),
    queryFn: ({ pageParam }) =>
      getAppValidation(id, { searchParams: { offset: pageParam?.nextOffset || 0 } }),
    initialPageParam: { nextOffset: 0 },
    getNextPageParam: (lastPage, pages = []) => {
      return lastPage.votes?.length === DEFAULT_APP_VALIDATION_VOTERS_LIMIT
        ? {
            nextOffset: pages.length * DEFAULT_APP_VALIDATION_VOTERS_LIMIT,
          }
        : undefined;
    },
    staleTime: 30000,
  });
};

export const useListingAppMutation = () => {
  return useMutation({ mutationFn: (data: ListingData) => listingApp({ json: data }) });
};

export const useEditAppMutation = () => {
  return useMutation({
    mutationFn: ({ appId, data }: { appId: string; data: ListingData }) =>
      editApp(appId, { json: data }),
  });
};

export const useAppCurrentUserMetaQuery = (
  appId: string,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getAppCurrentUserMeta>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: appQueryKeys.appCurrentUserMeta(appId),
    queryFn: () => getAppCurrentUserMeta(appId),
    ...options,
  });
};

export const prefetchAppCurrentUserMetaQuery = (
  appId: string,
  clientQuery: QueryClient,
  options: Parameters<typeof getAppCurrentUserMeta>[0],
) => {
  return clientQuery.prefetchQuery({
    queryKey: appQueryKeys.appCurrentUserMeta(appId),
    queryFn: () => getAppCurrentUserMeta(appId, options),
  });
};

export const useToggleAppLikeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ appId, like }: { appLink: string; appId: number; like: boolean }) => {
      if (like) {
        await addAppLike({ json: { id: appId } });
      } else {
        await removeAppLike({
          searchParams: {
            id: appId,
          },
        });
      }
    },
    onMutate: async ({ like, appLink }) => {
      const key = appQueryKeys.appCurrentUserMeta(appLink);
      await queryClient.cancelQueries({ queryKey: key });
      const previousLike = queryClient.getQueryData(key);

      queryClient.setQueryData(key, (old?: DeserializedAppCurrentUserMeta) =>
        old
          ? {
              ...old,
              attributes: {
                ...old.attributes,
                liked: like,
              },
            }
          : old,
      );

      return { previousLike };
    },
    onError: (error, data, context) => {
      const key = appQueryKeys.appCurrentUserMeta(data.appLink);

      queryClient.setQueryData(key, context?.previousLike);
    },
  });
};

export const useAddTrustReviewMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      trustLevel,
      title,
      text,
    }: {
      id: number;
      appId: string;
      trustLevel: number;
      title: string;
      text: string;
      user: DeserializedUser;
    }) => {
      return addTrustReview({
        json: {
          id,
          review_rating: trustLevel,
          review_title: title,
          review_text: text,
        },
      });
    },
    onSuccess: async (data, { user, appId, trustLevel, title, text }) => {
      const key = appQueryKeys.app(appId);

      await queryClient.cancelQueries({ queryKey: key });

      queryClient.setQueryData(key, (old?: DeserializedAppData) => {
        if (!old) {
          return old;
        }

        const newReview: DeserializedAppTrustReview = {
          id: data.id,
          type: 'TRUST_REVIEW',
          attributes: {
            rating: trustLevel.toString(),
            text,
            title,
            timestamp: data.create_time,
            userName: user.attributes.name,
          },
          user: {
            id: user.id,
            type: 'PUBLIC_USER',
            attributes: {
              icon: user.attributes.icon,
              karma: user.attributes.karma,
              description: user.attributes.description,
              uuid: user.attributes.uuid,
              magicId: user.attributes.name,
              name: user.attributes.displayedName,
              appsCount: 0,
            },
          },
        };

        return {
          ...old,
          trustReviews: [newReview, ...(old.trustReviews || [])],
        };
      });
    },
  });
};

const DEFAULT_INFINITE_REVIEWS_LIMIT = 10;

export const useInfiniteReviewsQuery = ({
  appId,
  limit = DEFAULT_INFINITE_REVIEWS_LIMIT,
}: GetAppReviewsSearchParams) =>
  useInfiniteQuery({
    queryKey: appQueryKeys.appInfiniteReviewList({ appId, limit }),
    queryFn: ({ pageParam }) =>
      getAppReviews({ searchParams: { appId, limit, offset: limit * pageParam } }),
    initialPageParam: 0,
    getNextPageParam: (lastPage = [], pages = []) =>
      lastPage.length === limit ? pages.length : undefined,
  });

export const prefetchAppInfiniteReviewsQuery = (
  queryClient: QueryClient,
  options: Parameters<typeof getAppReviews>[0],
  queryOptions?: FetchInfiniteQueryOptions<DeserializedAppReview[]>,
) => {
  const params = {
    limit: DEFAULT_INFINITE_REVIEWS_LIMIT,
    ...options.searchParams,
  };

  return queryClient.prefetchInfiniteQuery({
    queryKey: appQueryKeys.appInfiniteReviewList(params),
    queryFn: () => {
      return getAppReviews({
        ...options,
        searchParams: params,
      });
    },
    initialPageParam: 0,
    ...queryOptions,
  });
};

export const useAddUserReviewMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      rating,
      title,
      text,
    }: {
      id: number;
      appId: string;
      rating: number;
      title: string;
      text: string;
      user: DeserializedUser;
    }) => {
      return addAppReview({
        json: {
          id,
          review_rating: rating,
          review_title: title,
          review_text: text,
        },
      });
    },
    onMutate: async ({ user, appId, rating, title, text }) => {
      const key = appQueryKeys.appInfiniteReviewList({
        appId,
        limit: DEFAULT_INFINITE_REVIEWS_LIMIT,
      });
      await queryClient.cancelQueries({ queryKey: key });
      const previousData = queryClient.getQueryData(key);

      queryClient.setQueryData(key, (old?: InfiniteData<DeserializedAppReview[]>) => {
        if (!old) {
          return old;
        }

        const newReview: DeserializedAppReview = {
          id: Date.now(),
          type: 'REVIEW',
          attributes: {
            rating,
            text,
            title,
            funnyVotes: 0,
            negativeVotes: 0,
            positiveVotes: 0,
            userVote: 0,
            userFunnyVote: false,
            timestamp: new Date().toISOString(),
            userName: user.attributes.name,
          },
          user: {
            id: user.id,
            type: 'PUBLIC_USER',
            attributes: {
              icon: user.attributes.icon,
              karma: user.attributes.karma,
              description: user.attributes.description,
              uuid: user.attributes.uuid,
              magicId: user.attributes.name,
              name: user.attributes.displayedName,
              appsCount: 0,
            },
          },
        };
        const [firstPage, ...otherPages] = old.pages;

        return {
          ...old,
          pages: firstPage ? [[newReview, ...firstPage], ...otherPages] : [[newReview]],
        };
      });

      return { previousData };
    },
    onSuccess: (data, { appId }) => {
      queryClient.invalidateQueries({
        queryKey: appQueryKeys.appInfiniteReviewList({
          appId,
          limit: DEFAULT_INFINITE_REVIEWS_LIMIT,
        }),
      });
    },
    onError: (error, { appId }, context) => {
      const key = appQueryKeys.appInfiniteReviewList({
        appId,
        limit: DEFAULT_INFINITE_REVIEWS_LIMIT,
      });

      queryClient.setQueryData(key, context?.previousData);
    },
  });
};

export const useAddAppSuggestionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      title,
      text,
    }: {
      id: number;
      appId: string;
      title: string;
      text: string;
      user: DeserializedUser;
    }) => {
      return addAppSuggestion({
        json: {
          id,
          suggestion_title: title,
          suggestion_text: text,
        },
      });
    },
    onMutate: async ({ user, appId, title, text }) => {
      const key = appQueryKeys.app(appId);
      await queryClient.cancelQueries({ queryKey: key });
      const previousData = queryClient.getQueryData(key);

      queryClient.setQueryData(key, (old?: DeserializedAppData) => {
        if (!old) {
          return old;
        }

        const newReview: DeserializedAppSuggestion = {
          id: Date.now(),
          type: 'SUGGESTION',
          attributes: {
            text,
            title,
            timestamp: new Date().toISOString(),
            userName: user.attributes.name,
          },
          user: {
            id: user.id,
            type: 'PUBLIC_USER',
            attributes: {
              icon: user.attributes.icon,
              karma: user.attributes.karma,
              description: user.attributes.description,
              uuid: user.attributes.uuid,
              magicId: user.attributes.name,
              name: user.attributes.displayedName,
              appsCount: 0,
            },
          },
        };

        return {
          ...old,
          suggestion: [newReview, ...(old.suggestion || [])],
        };
      });

      return { previousData };
    },
    onError: (error, data, context) => {
      const key = appQueryKeys.app(data.appId);

      queryClient.setQueryData(key, context?.previousData);
    },
  });
};

export const useVoteForReviewMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof voteForReview>>,
    TError,
    { reviewType: ReviewType; reviewId: number; vote: ReviewVote },
    TContext
  >,
) => {
  return useMutation({
    mutationFn: ({ reviewType, reviewId, vote }) => {
      return voteForReview(
        {
          id: reviewId,
          type: reviewType,
        },
        { json: { vote } },
      );
    },
    ...options,
  });
};

export const useLikeReviewMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof likeReview>>,
    TError,
    { reviewType: ReviewType; reviewId: number; like: boolean },
    TContext
  >,
) => {
  return useMutation({
    mutationFn: ({ reviewId, reviewType, like }) => {
      return likeReview(
        {
          id: reviewId,
          type: reviewType,
        },
        { json: { funny: like } },
      );
    },
    ...options,
  });
};
