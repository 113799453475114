// Define the type of the keys
export type GlobalEnvConfigName = 'isDev' | 'isLocalEnv' | 'socketHost' | 'apiHost' | 'host';

// Function overloads
export function getProcessEnvGlobalConfig(key: 'isDev' | 'isLocalEnv'): boolean;

export function getProcessEnvGlobalConfig(key: 'socketHost' | 'apiHost' | 'host'): string;

// Implementation
export function getProcessEnvGlobalConfig(key: GlobalEnvConfigName) {
  switch (key) {
    case 'isDev':
      return typeof process !== 'undefined'
        ? process?.env?.NEXT_PUBLIC_IS_DEVELOPMENT === 'true'
        : true;
    case 'isLocalEnv':
      return typeof process !== 'undefined'
        ? process?.env?.NEXT_PUBLIC_ENVIRONMENT === 'local'
        : true;
    case 'socketHost':
      return typeof process !== 'undefined' ? process?.env?.NEXT_PUBLIC_SOCKET_HOST || '' : '';
    case 'apiHost':
      return typeof process !== 'undefined' ? process?.env?.NEXT_PUBLIC_API_HOST || '' : '';
    case 'host':
      return typeof process !== 'undefined' ? process?.env?.NEXT_PUBLIC_HOST || '' : '';
    default:
      throw new Error(`Unhandled global config ENV name: ${key}`);
  }
}
