import { Options } from 'ky';

import { deserialize, JsonApiObject } from '@shared/helpers/jsonapi';

import Api, { ApiOptions } from './ApiProvider';
import {
  FcfsTransaction,
  LaunchpadProject,
  LaunchpadProjectListItem,
  LaunchpadProjectStatus,
  LaunchpadSalePool,
} from './types';

export const getLaunchpadProjects = async (
  options?: ApiOptions<{
    searchParams?: {
      limit?: number;
      offset?: number;
      status?: LaunchpadProjectStatus;
      earlyAccess?: boolean;
    };
  }>,
) => {
  const data = await Api.get('launchpad/projects', options).json<JsonApiObject>();
  return deserialize<LaunchpadProjectListItem[]>(data);
};

export const getLaunchpadProject = async (id: string) => {
  const data = await Api.get(`launchpad/projects/${id}`).json<JsonApiObject>();
  return deserialize<LaunchpadProject>(data);
};

export const getLaunchpadProjectWithSales = async (id: string, options?: Options) => {
  const data = await Api.get(`launchpad/projects/${id}/pools`, options).json<JsonApiObject>();
  return deserialize<LaunchpadProject>(data);
};

export const getSelfLaunchpadProjectWithSales = async (id: string, options?: Options) => {
  const data = await Api.get(
    `launchpad/projects/${id}/pools-with-user-participation`,
    options,
  ).json<JsonApiObject>();
  return deserialize<LaunchpadProject>(data);
};

export const getLaunchpadSale = async (id: string) => {
  const data = await Api.get(`launchpad/pools/${id}`).json<JsonApiObject>();
  return deserialize<LaunchpadSalePool>(data);
};

export const getLaunchpadSales = async (id: string) => {
  return Api.get(`launchpad/pools`, {
    searchParams: {
      projectId: id,
    },
  }).json<{ data: LaunchpadSalePool[] }>();
};

export const depositLaunchpadFcfs = async (saleId: string, amount: number) => {
  const data = await Api.post(`launchpad/pools/fcfs/${saleId}/transactions`, {
    json: { amount },
  }).json<JsonApiObject>();
  return deserialize<FcfsTransaction>(data);
};

export const depositLaunchpadSqrpGated = async (saleId: string, amount: number) => {
  const data = await Api.post(
    `launchpad/pools/sqrp-gated/${saleId}/transactions
  `,
    {
      json: { amount },
    },
  ).json<JsonApiObject>();
  return deserialize<FcfsTransaction>(data);
};

export const depositLaunchpadProRataSqrpGated = async (
  saleId: string,
  amount: number,
  boosted: boolean,
) => {
  const data = await Api.post(
    `launchpad/pools/pro-rata-sqrp-gated/${saleId}/transactions
  `,
    {
      json: { turboCharge: boosted, amount },
    },
  ).json<JsonApiObject>();
  return deserialize<FcfsTransaction>(data);
};

export const depositLaunchpadProRata = async (saleId: string, amount: number, boosted: boolean) => {
  const data = await Api.post(
    `launchpad/pools/pro-rata/${saleId}/transactions
  `,
    {
      json: { turboCharge: boosted, amount },
    },
  ).json<JsonApiObject>();
  return deserialize<FcfsTransaction>(data);
};

export const depositLaunchpadSqrp = async (saleId: string, amount: number) => {
  const data = await Api.post(
    `launchpad/pools/sqrp/${saleId}/transactions
  `,
    {
      json: { amount },
    },
  ).json<JsonApiObject>();
  return deserialize<FcfsTransaction>(data);
};
