import { Address } from 'viem';

export interface StoreRow {
  id: number;
  type: 'ROW';
  attributes: {
    type: 'SMALL' | 'BIG';
    index: number;
  };
  relationships: {
    blocks: {
      id: number;
      type: 'BLOCK';
    }[];
  };
}

export interface StoreBlock {
  id: number;
  type: 'BLOCK';
  attributes: {
    index: number;
    type: string;
    title: string | null;
    filter: string | null;
    see_all: boolean | null;
    image: string | null;
    link: string | null;
  };
  relationships?: {
    apps: {
      data: { type: string; id: number }[];
    };
  };
}

export interface StoreApp {
  id: number;
  type: 'APP';
  attributes: {
    appId: string;
    link: string;
    externalLink: string | null;
    appWebsite: string | null;
    googlePlayLink: string | null;
    appStoreLink: string | null;
    isAdaptive: boolean;
    devices: string[] | null;
    name: string;
    icon: string;
    verified: boolean;
    productionReady: boolean;
    appChain: string;
    appBanner: string | null;
    description: string;
    shortDescription: string;
    iframeConfirm: boolean;
    category: string;
    subcategory: string;
    status: string;
  };
}

export interface StorePage {
  data: {
    id: string | number;
    type: string;
    meta: {
      seo_description: string;
      seo_keywords: string;
      seo_title: string;
    };
    attributes: {
      description: string;
      name: string;
    };
    relationships: {
      id: string;
      type: 'ROW';
    }[];
  };
  included: (StoreApp | StoreRow | StoreBlock)[];
}

export const STORE_PAGE_ID = {
  apps: 'apps',
  discover: 'discover',
  upcoming: 'upcoming',
  games: 'games',
} as const;

export type StorePageName = keyof typeof STORE_PAGE_ID;

export type StorePageId = (typeof STORE_PAGE_ID)[StorePageName];

export type GetStorePageDataParams = {
  page: StorePageId;
  chain?: string;
};

export interface ChainWithAppsCount {
  chain_name: string;
  chain_short_name: string | null;
  count: string;
  icon: string | null;
}

export interface CarouselItem {
  blockLink: string;
  id: string;
  image: string;
  hotOfferId: string;
  appId: string;
  blockOptionAlt?: string;
  blockOptionTitle?: string;
  blockOptionDescription?: string;
  blockOptionSeoDescription?: string;
  blockOptionSeoTitleDescription?: string;
}

export interface AppPreview {
  dist: number;
  id: number;
  app_id: string;
  app_icon: string;
  app_name: string;
  status: string;
  search_rank: number;
  search: string;
  category_id: number;
  category_name: string;
  subcategory_name: string;
  subcategory_id: string;
}

export interface StoreSearchAppsSearchParams {
  find?: string;
  limit?: number;
  category?: number;
  offset?: number;
  subcategory?: number;
  order?: string;
  tags?: string;
}

export interface StoreSearchApp {
  app_banner: string;
  id: number;
  app_id: string;
  app_icon: string;
  app_name: string;
  is_adaptive: boolean;
  is_verified: boolean;
  iframe_confirm: boolean;
  production_ready: boolean;
  devices: Record<'web' | 'mobile' | 'tablet' | 'desktop', boolean>;
  google_play_link: string | null;
  app_store_link: string | null;
  desktop_app_link: string | null;
  app_website: string | null;
  is_free: boolean;
  link: string | null;
  category_id: number;
  category_name: string;
  subcategory_name: string;
  subcategory_id: number;
  status: string;
  dist: number;
  description: string;
}

export interface StoreChain {
  name: string;
  icon?: string | null;
}

export interface AppSearch {
  data: {
    id: number;
    app_id: string;
    app_icon: string;
    app_name: string;
    category_id: number;
    category_name: string;
    search: string;
    search_rank: number;
    status: string;
    subcategory_id: number;
    subcategory_name: string;
  }[];
  total_apps: number;
}

export interface DeserializedStorePageApp {
  id: number;
  type: 'APP';
  attributes: {
    appId: string;
    link: string;
    externalLink: string;
    appWebsite: string | null;
    googlePlayLink: string | null;
    appStoreLink: string | null;
    isAdaptive: boolean;
    devices: null;
    name: string;
    icon: string;
    verified: boolean;
    productionReady: boolean;
    appChain: string | null;
    appBanner: string | null;
    description: string;
    shortDescription: string;
    iframeConfirm: boolean;
    category: string;
    subcategory: string;
    status: string;
  };
  hotOffers?: {
    id: number;
    type: 'HOT_OFFER';
    attributes: {
      offerId: string;
    };
  }[];
}

export interface DeserializedStoreGeneralPageBlock {
  id: number;
  type: 'BLOCK';
  attributes: {
    type: string;
    [key: string]: any;
  };
}

export interface DeserializedStorePageGeneralBannerBlock<
  BlockOptions extends
    | {
        type: string;
      }
    | undefined = undefined,
> extends DeserializedStoreGeneralPageBlock {
  attributes: {
    type: 'BANNER';
    blockOptions: BlockOptions;
    image: string;
    index: number;
    link: string;
  };
}

export type DeserializedStorePageStoryBannerBlock = DeserializedStorePageGeneralBannerBlock<{
  type: 'STORY';
  appAttached: boolean;
  appId?: string;
  hotOfferId?: string;
  title?: string;
  description?: string;
  imageAlt?: string;
}>;

export type DeserializedStorePageImageBannerBlock = DeserializedStorePageGeneralBannerBlock<
  | {
      type: 'IMAGE';
      imageAlt?: string;
      showAd?: boolean;
      adZoneId?: string;
      adAccountId?: string;
      adZoneWidth?: string;
      adZoneHeight?: string;
    }
  | undefined
>;

export type DeserializedStorePageBannerBlock =
  | DeserializedStorePageStoryBannerBlock
  | DeserializedStorePageImageBannerBlock;

export interface DeserializedStorePageCarouselBlock extends DeserializedStoreGeneralPageBlock {
  attributes: {
    type: 'CAROUSEL';
    items: CarouselItem[];
  };
}

export interface DeserializedStorePageBigAppBlock extends DeserializedStoreGeneralPageBlock {
  attributes: {
    type: 'BIG_APP';
    filter: string | null;
    image: string | null;
    index: number;
    link: string | null;
    see_all: boolean | null;
    title: string | null;
    uuid: string;
  };
  apps: [DeserializedStorePageApp];
}

export interface DeserializedStorePageAppsBlock extends DeserializedStoreGeneralPageBlock {
  attributes: {
    type: 'APPS4' | 'APPS6' | 'APPS12';
    filter: string;
    image: string | null;
    index: number;
    link: string;
    see_all: boolean;
    title: string;
    uuid: string;
  };
  apps: DeserializedStorePageApp[];
  meta: {
    seo_description: string;
    seo_keywords: string;
    seo_title: string;
  };
}

export type DeserializedStorePageBlock =
  | DeserializedStorePageBannerBlock
  | DeserializedStorePageAppsBlock
  | DeserializedStorePageBigAppBlock
  | DeserializedStorePageCarouselBlock;

export interface DeserializedStorePageRow {
  id: number;
  type: 'ROW';
  attributes: {
    index: number;
    type: 'SMALL' | 'MEDIUM' | 'BIG';
  };
  blocks: DeserializedStorePageBlock[];
}

export interface DeserializedStorePage {
  id: string;
  meta: {
    seo_description: string;
    seo_keywords: string;
    seo_title: string;
  };
  attributes: {
    description: string;
    name: string;
  };
  rows: DeserializedStorePageRow[];
}

export interface AutoLayerCampaignParticipant {
  id: Address;
  type: 'SOQUEST_PARTICIPANT';
  attributes: {
    soQuestPoints: number;
    autoLayerPoints: number;
    totalPoints: number;
    position: number;
  };
}
