import {
  QueryClient,
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';

import {
  DEFAULT_LIMIT_LAUNCHPAD_NOTIFICATION,
  getUserLaunchpadNotification,
  GetUserLaunchpadNotificationSearchParams,
  LAUNCHPAD_NOTIFICATION_CATEGORY,
} from '@api';

import { ApiOptions } from '@shared/helpers/api';

import { AUTH_USER_QUERY_KEY } from './keys';

const queryKeys = {
  notification: [AUTH_USER_QUERY_KEY, 'launchpadUserNotifications'],
  userAllocations: [AUTH_USER_QUERY_KEY, 'launchpadUserAllocations'],
  infiniteNotificationList: ({
    limit = DEFAULT_LIMIT_LAUNCHPAD_NOTIFICATION,
    offset = 0,
    category = LAUNCHPAD_NOTIFICATION_CATEGORY.ALL,
  }: GetUserLaunchpadNotificationSearchParams = {}) => [
    AUTH_USER_QUERY_KEY,
    'launchpadUserNotificationsInfinity',
    { limit, offset, category },
  ],
};

export const prefetchLaunchpadUserNotificationsQuery = (
  clientQuery: QueryClient,
  options?: ApiOptions<{
    searchParams?: { limit?: number; offset?: number };
  }>,
) => {
  return clientQuery.prefetchQuery({
    queryKey: queryKeys.notification,
    queryFn: () => getUserLaunchpadNotification(options),
  });
};

export const useLaunchpadUserNotificationsQuery = (
  options?: UseQueryOptions<Awaited<ReturnType<typeof getUserLaunchpadNotification>>>,
) => {
  return useQuery({
    queryKey: queryKeys.notification,
    queryFn: () => getUserLaunchpadNotification(),
    staleTime: 0,
    ...options,
  });
};

export const useSelfNotificationListInfiniteQuery = ({
  params,
  options,
}: {
  params?: Parameters<typeof getUserLaunchpadNotification>[0];
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getUserLaunchpadNotification>>,
      unknown,
      unknown,
      any,
      number
    >,
    'enabled'
  >;
} = {}) => {
  return useInfiniteQuery({
    queryKey: queryKeys.infiniteNotificationList(params?.searchParams),
    queryFn: ({ pageParam: offset }) =>
      getUserLaunchpadNotification({
        searchParams: {
          ...params?.searchParams,
          offset,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage?.length === DEFAULT_LIMIT_LAUNCHPAD_NOTIFICATION
        ? pages.length * DEFAULT_LIMIT_LAUNCHPAD_NOTIFICATION
        : undefined,
    staleTime: 0,
    ...options,
  });
};
