import { Options } from 'ky';

import Api from './ApiProvider';

export interface Translations {
  [key: string]: Translations | string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type TranslationsLocale = 'en' | (string & {});

export type TranslationList = Record<TranslationsLocale, Translations>;

export const getTranslations = (options?: Options) => {
  return Api.get('v2/cms/i18n/translation/list', options).json<TranslationList>();
};
