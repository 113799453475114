import {
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  getNotifications,
  removeIdArrNewStatus,
  removeIdArrNotification,
  removeIdNotification,
} from '@api';

const userQueryKeys = {
  notificationList: (params: number) => ['notificationList', params],
};

export const useNotificationInfiniteQuery = ({
  limit = 10,
  options,
}: {
  limit?: number;
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getNotifications>>,
      unknown,
      unknown,
      any,
      number
    >,
    'enabled'
  >;
} = {}) => {
  return useInfiniteQuery({
    queryKey: userQueryKeys.notificationList(limit),
    queryFn: ({ pageParam: nextOffset }) =>
      getNotifications({ searchParams: { limit, offset: nextOffset || 0 } }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage.rows.length === limit ? limit * pages.length : undefined,
    staleTime: 30000,
    ...options,
  });
};

export const useDeleteNotificationMutate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, type }: { id: number | number[]; type: 'list' | 'item' }) => {
      if (type === 'list') {
        await removeIdArrNotification({ json: { ids: id } });
      }

      if (type === 'item') {
        await removeIdNotification({ json: { id } });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.notificationList(10) });
    },
  });
};

export const useMarkAsReadMutate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (ids: number[]) => {
      await removeIdArrNewStatus({ json: { ids } });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.notificationList(10) });
    },
  });
};
