import { Options } from 'ky';

import { TelegramUser } from '@api';

import Api, { ApiOptions } from './ApiProvider';

export const deleteUserSocial = (options?: ApiOptions<{ searchParams: { id: number } }>) =>
  Api.delete('v1/magicid/social', options).json<{
    createdAt: string;
    id: number;
    isVerified: number;
    link: string;
    social: string;
    updatedAt: boolean;
    userId: number;
    visibleOnMagicId: boolean;
  }>();

export const getDiscordLink = (options?: Options) =>
  Api.get('v2/magicid/user/socials/discord/connect', options).json<{
    url: string;
  }>();

export const verifyDiscord = (options: ApiOptions<{ json: { code: string; state: string } }>) =>
  Api.post('v1/magicid/socials/discord/verify', options).json();

export const getTwitterLink = () =>
  Api.get('v1/magicid/socials/twitter/generateVerifyMessage').json<{
    urlWithMessage: string;
    verifyMessage: string;
  }>();

export const verifyTwitterLink = (options: ApiOptions<{ json: { tweetUrl: string } }>) =>
  Api.post('v1/magicid/socials/twitter/verify', options);

export const connectTelegram = (options: ApiOptions<{ json: { userData: TelegramUser } }>) =>
  Api.post('v2/magicid/user/socials/telegram/verify', options).json();

export const getConnectXUrl = (options?: ApiOptions) =>
  Api.get('v2/magicid/user/socials/x/connect', options).json<{ meta: { link: string } }>();

export interface VerifyXConnectParams {
  oauthToken: string;
  oauthVerifier: string;
}

export const verifyXConnect = (options: ApiOptions<{ json: VerifyXConnectParams }>) => {
  return Api.post('v2/magicid/user/socials/x/verify', options).json();
};
