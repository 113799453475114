import { Options } from 'ky';

import { deserialize, JsonApiObject } from '@shared/helpers/jsonapi';

import Api, { ApiOptions } from './ApiProvider';
import {
  CampainList,
  CampainListParams,
  CampainReviewFiltersList,
  CampainReviewListRequest,
  CampainReviewListResponse,
  CurrentDeveloperAppList,
  DeveloperAppList,
  DeveloperProfile,
  DeveloperTeamMemberList,
  DeveloperToken,
  UpdateDeveloperProfileData,
} from './types';

export const getCurrentDeveloperProfile = (options?: Options) => {
  return Api.get('v2/store/creator/profile', options).json<DeveloperProfile>();
};

export const getDeveloperTeamMembers = (
  options: ApiOptions<{ searchParams: { creator_id: string } }>,
) => Api.get('v1/main/creator/members', options).json<DeveloperTeamMemberList>();

// TODO: we have the same endpoint
export const getCurrentDeveloperApps = (options?: Options) => {
  return Api.get('v2/store/creator/apps', options).json<CurrentDeveloperAppList>();
};

export const getDeveloperApps = (options: ApiOptions<{ searchParams: { creator_id: string } }>) =>
  Api.get('v2/store/creator/apps/public', options).json<DeveloperAppList>();

export const getDeveloperTokens = (
  options: ApiOptions<{ searchParams: { creator_id: string } }>,
) => {
  return Api.get('v2/store/creator/tokens', options).json<DeveloperToken[]>();
};

export const createDeveloperProfile = (
  options: ApiOptions<{ json: UpdateDeveloperProfileData }>,
) => {
  return Api.post('v2/store/creator', options).json();
};

export const checkCreatorEmail = (options: ApiOptions<{ searchParams: { email: string } }>) =>
  Api.get('v2/store/creator/profile/email/check', options).json<{
    email_busy: boolean;
  }>();

export const updateCurrentDeveloperProfile = (
  options: ApiOptions<{ json: UpdateDeveloperProfileData }>,
) => {
  return Api.put('v2/store/creator/profile', options).json();
};

export const getDeveloperProfile = (creatorId: string, options?: ApiOptions) =>
  Api.get(`v2/store/creator/${creatorId}/public`, options).json<DeveloperProfile>();

export const sendDeveloperProfileEmailVerificationCode = (
  options: ApiOptions<{ json: { email: string } }>,
) =>
  Api.post('v2/store/creator/profile/email/send-code', options).json<{
    result: string;
  }>();

export const verifyDeveloperProfileEmailVerificationCode = (
  options: ApiOptions<{ json: { email: string; code: number } }>,
) =>
  Api.post('v2/store/creator/profile/email/verify', options).json<
    { result: true } | { result: false; attempts: number }
  >();

export const getDeveloperShareLink = (creatorId: string, options?: ApiOptions) =>
  Api.get(`v2/store/creator/${creatorId}/share-link`, options).json<{ sharingLink: string }>();

export const getCampainList = async (options: ApiOptions<{ searchParams: CampainListParams }>) => {
  const data = await Api.get(`v2/hot-offers/pow/creator/claims`, options).json<JsonApiObject>();

  return deserialize<CampainList[]>(data);
};

export const getHotOffersFilterList = () =>
  Api.get(`v2/hot-offers/pow/creator/hot-offers`).json<{
    data: CampainReviewFiltersList[];
  }>();

export const reviewCampainList = (
  options: ApiOptions<{
    json: CampainReviewListRequest;
  }>,
) => {
  const { status } = options.json;
  const otherOptions = options;

  delete otherOptions.json.status;

  return Api.patch(
    `v2/hot-offers/pow/creator/${status}`,
    otherOptions,
  ).json<CampainReviewListResponse>();
};
