import { captchaDecorator } from '@shared/helpers/captcha';

import { deserialize, JsonApiObject } from '../helpers/jsonapi';
import Api, { ApiOptions } from './ApiProvider';
import {
  BinanceCheckoutEntry,
  CanClaimOfferEntry,
  ClaimPowOfferResponse,
  DeserializedHotOffer,
  HotOffersEntry,
} from './types/hotOffers';

export const getHotOffer = async (offerId: string, options?: ApiOptions) => {
  const rawApp = await Api.get(
    `v2/hot-offers/how-to-get/${offerId}`,
    options,
  ).json<JsonApiObject>();

  return deserialize<DeserializedHotOffer>(rawApp);
};

export const getHotToGetOffer = async (
  options: ApiOptions<{
    searchParams: {
      offerId: string;
    };
  }>,
) => {
  const rawApp = await Api.get(
    `v2/hot-offers/how-to-get/${options.searchParams.offerId}`,
  ).json<JsonApiObject>();

  return deserialize<DeserializedHotOffer>(rawApp);
};

export const HOT_OFFERS_SORT = {
  NEW: 'new',
  CASHBACK: 'cashback',
  GENERAL: 'general',
  SOQUEST: 'soquest',
  POW: 'pow',
} as const;

export const GET_HOT_OFFERS_DEFAULT_LIMIT = 12;

export const GET_HOT_OFFERS_DEFAULT_SORT = HOT_OFFERS_SORT.NEW;

type HotOffersSortName = keyof typeof HOT_OFFERS_SORT;

export type HotOffersSortValue = (typeof HOT_OFFERS_SORT)[HotOffersSortName];

export type GetHotOffersSearchParams = {
  limit?: number;
  offset?: number;
  sort?: HotOffersSortValue;
};

export const isGetHotOffersSortParam = (value: unknown): value is HotOffersSortValue => {
  return (
    !!value &&
    typeof value === 'string' &&
    Object.values(HOT_OFFERS_SORT).includes(value as HotOffersSortValue)
  );
};

export const getHotOffersSearchParams = (searchParams?: {
  sort?: string;
}): Required<Pick<GetHotOffersSearchParams, 'sort'>> => {
  const sort = searchParams?.sort?.toLowerCase();

  if (!sort) {
    return {
      sort: GET_HOT_OFFERS_DEFAULT_SORT,
    };
  }

  return {
    sort: isGetHotOffersSortParam(sort) ? sort : GET_HOT_OFFERS_DEFAULT_SORT,
  };
};

export const getHotOffers = (
  options?: ApiOptions<{
    searchParams: GetHotOffersSearchParams;
  }>,
) => {
  const optionsWithDefaults = {
    ...options,
    searchParams: {
      limit: GET_HOT_OFFERS_DEFAULT_LIMIT,
      sort: GET_HOT_OFFERS_DEFAULT_SORT,
      ...options?.searchParams,
    },
  };

  return Api.get('v2/hot-offers', optionsWithDefaults).json<HotOffersEntry>();
};

export const getCanClaimOffer = (offerId: string, options?: ApiOptions) =>
  Api.get(`v2/hot-offers/how-to-get/${offerId}/allowance`, options).json<CanClaimOfferEntry>();

export const createBinancePay = captchaDecorator<{ searchParams: { offerId: string } }>(
  async (options) =>
    Api.post('hot-offers/cashback/create-order', options).json<BinanceCheckoutEntry>(),
  'createBinancePay',
);

export const getOfferClaimUrl = (offerId: string, options?: ApiOptions) =>
  Api.post(`v2/hot-offers/general/${offerId}/claim`, options).json<{
    id: string;
    type: string;
    attributes: {
      url: string;
    };
  }>();

export const getPowOfferClaim = (options: ApiOptions<{ json: { offerId: string } }>) => {
  const { offerId } = options.json;

  return Api.post(`v2/hot-offers/pow/${offerId}/claim`, options).json<ClaimPowOfferResponse>();
};

export const getHotOfferShareLink = (offerId: string, options?: ApiOptions) =>
  Api.get(`v2/store/hot-offers/${offerId}/share-link`, options).json<{ sharingLink: string }>();

export interface AddPowOfferProofsParams {
  fileUuids?: string[];
  link?: string;
  text?: string;
  offerId?: string;
}

export const addPowOfferProof = (options: ApiOptions<{ json: AddPowOfferProofsParams }>) => {
  const { offerId } = options.json;

  const otherOptions = options;
  delete otherOptions.json.offerId;

  return Api.post(`v2/hot-offers/pow/${offerId}/claim/proof`, otherOptions).json<{
    status: string;
  }>();
};
