import { Options } from 'ky';

import { CategoryApp, OldAppResponse } from '@api';

import Api from './ApiProvider';

// TODO: Ensure that is works fine after Spaces is done fetchFileBlob
export const fetchFileBlob = (url: string, Authorization: string) => {
  return Api.get(url, { headers: { Authorization } });
};

export const getCurrentUserApps = (options?: Options) =>
  Api.get('v2/store/creator/apps', options).json<OldAppResponse>();

export const fetchCategories = (options?: Options) =>
  Api.get('v2/store/meta/categories', options).json<CategoryApp[]>();
