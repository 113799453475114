import { useMutation } from '@tanstack/react-query';

import { subscribeToMagicIdNewsletter } from '@api';

export const useSubscribeToMagicIdNewsletterMutation = () => {
  return useMutation({
    mutationFn: (params: Parameters<typeof subscribeToMagicIdNewsletter>[0]['json']) =>
      subscribeToMagicIdNewsletter({ json: params }),
  });
};
