import { KYC_STATUS, KycStatus } from '@api';

export const getKycStatusFlags = (
  status?: KycStatus,
): {
  hasVerifiedKyc: boolean;
  isNeedVerification: boolean;
  isError: boolean;
} => {
  const needVerificationStatuses: KycStatus[] = [
    KYC_STATUS.INIT,
    KYC_STATUS.PENDING,
    KYC_STATUS.DELETED,
    KYC_STATUS.REJECTED_RETRY,
  ];
  const successStatuses: KycStatus[] = [KYC_STATUS.COMPLETED];
  const errorStatuses: KycStatus[] = [KYC_STATUS.REJECTED_FINAL];

  if (!status || needVerificationStatuses.includes(status)) {
    return {
      hasVerifiedKyc: false,
      isNeedVerification: true,
      isError: false,
    };
  }

  if (successStatuses.includes(status)) {
    return {
      hasVerifiedKyc: true,
      isNeedVerification: false,
      isError: false,
    };
  }

  if (errorStatuses.includes(status)) {
    return {
      hasVerifiedKyc: false,
      isNeedVerification: false,
      isError: true,
    };
  }

  return {
    hasVerifiedKyc: false,
    isNeedVerification: false,
    isError: false,
  };
};

export const kycStatusLabels: Record<KycStatus, string> = {
  [KYC_STATUS.INIT]: 'Get Verified',
  [KYC_STATUS.PENDING]: 'Verification in progress',
  [KYC_STATUS.COMPLETED]: 'Verified',
  [KYC_STATUS.DELETED]: 'Verification data removed',
  [KYC_STATUS.REJECTED_RETRY]: 'Please retry verification',
  [KYC_STATUS.REJECTED_FINAL]: 'Unable to verify',
};
