export const isValidUrl = (url: string) => {
  try {
    return !!new URL(url);
  } catch (error) {
    return false;
  }
};

export const prependHttp = (url: string) => {
  if (/^https?:\/\//.test(url)) {
    return url;
  }

  return `http://${url}`;
};

export const setMagicStoreUrmSource = (url: string) => {
  const normalizedUrl = prependHttp(url);

  if (!isValidUrl(normalizedUrl)) {
    return normalizedUrl;
  }

  const urlObj = new URL(normalizedUrl);

  urlObj.searchParams.set('utm_source', 'MagicStore');

  return urlObj.toString();
};

export const isPathMatches = (pattern: string, path: string) => {
  const patternParts = pattern.split('/');
  const pathParts = path.split('/');

  if (patternParts.length !== pathParts.length) {
    return false;
  }

  return patternParts.every((patternPart, i) => {
    if (patternPart.startsWith(':')) {
      return true;
    }

    const pathPart = pathParts[i];

    return patternPart === pathPart;
  });
};

export const joinPath = (path: (string | undefined)[]) =>
  path
    .map((segment) => segment?.trim().replace(/^\/|\/$/g, ''))
    .filter((segment) => !!segment && segment !== '/')
    .join('/');

export type SearchDomainParamValue = [field: string, '=', value: string][];

export const normalizeSearchDomainParam = (
  name: string,
  value?: SearchDomainParamValue,
): Record<string, string> => {
  if (!value?.length) {
    return {};
  }

  const params: Record<string, string> = {};

  for (let i = 0; i < value.length; i++) {
    for (let l = 0; l < value[i].length; l++) {
      params[`${name}[${i}][${l}]`] = value[i][l];
    }
  }

  return params;
};
