import {
  InvalidateQueryFilters,
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  addPowOfferProof,
  AddPowOfferProofsParams,
  GET_HOT_OFFERS_DEFAULT_LIMIT,
  getHotOffers,
  GetHotOffersSearchParams,
} from '@api';

import { hotOfferQueryKeys } from './hotOffer';

const hotOffersQueryKeys = {
  infiniteList: (searchParams: GetHotOffersSearchParams = {}) => [
    'infiniteHotOffersList',
    searchParams,
  ],
  offerShareUrl: 'offerShareUrl',
} as const;

export const useHotOffersInfinityQuery = (
  params: Omit<GetHotOffersSearchParams, 'offset' | 'limit'>,
) => {
  return useInfiniteQuery({
    queryKey: hotOffersQueryKeys.infiniteList(params),
    queryFn: ({ pageParam: offset }) =>
      getHotOffers({
        searchParams: {
          ...params,
          offset,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage?.data.length === GET_HOT_OFFERS_DEFAULT_LIMIT
        ? pages.length * GET_HOT_OFFERS_DEFAULT_LIMIT
        : undefined,
  });
};

export const prefetchHotOffersInfiniteQuery = async (
  clientQuery: QueryClient,
  params?: Parameters<typeof getHotOffers>[0],
) => {
  const queryKey = hotOffersQueryKeys.infiniteList(params?.searchParams);

  await clientQuery.prefetchInfiniteQuery({
    queryKey,
    queryFn: () =>
      getHotOffers({
        ...params,
        searchParams: {
          ...params?.searchParams,
          offset: 0,
        },
      }),
    initialPageParam: 0,
  });

  return clientQuery.getQueryData(queryKey);
};

export const useAddPowOfferProofMutation = (offerId?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: AddPowOfferProofsParams) => {
      await addPowOfferProof({ json: params });
    },
    onSettled: () => {
      const queryKey = hotOfferQueryKeys.powHotOffer(offerId || '') as InvalidateQueryFilters;
      queryClient.invalidateQueries(queryKey);
    },
  });
};
