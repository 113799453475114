import { Options } from 'ky';

import { NotificationList } from '@api';

import Api from './ApiProvider';

export const getNotifications = (options?: Options) =>
  Api.get('v1/notifier/notifications', options).json<NotificationList>();

export const removeIdNotification = (options?: Options) =>
  Api.delete('v1/notifier/notifications/delete', options).json();

export const removeAllNotification = (options?: Options) =>
  Api.delete('v1/notifier/notifications/delete/all', options).json();

export const removeIdArrNotification = (options?: Options) =>
  Api.delete('v1/notifier/notifications/delete/some', options).json();

export const removeNewStatus = (options?: Options) =>
  Api.put('v1/notifier/notifications/read/', options).json();

export const removeAllNewStatus = (options?: Options) =>
  Api.put('v1/notifier/notifications/read/all', options).json();

export const removeIdArrNewStatus = (options?: Options) =>
  Api.put('v1/notifier/notifications/read/some', options).json();
