import { Options } from 'ky';
import { Address } from 'viem';

import {
  DeserializedSelfAllocations,
  LaunchpadSalePoolStatus,
  LaunchpadSalePoolType,
  LaunchpadSalePoolWhitelistStrategy,
  subscribeToMagicIdNewsletter,
} from '@api';

import { WagmiConfigChain } from '@shared/common/providers/Web3Provider/wagmi';
import { deserializeJsonApi, JsonApiObject } from '@shared/helpers/jsonapi';

import Api, { ApiOptions } from './ApiProvider';
import { PaginationSearchParams } from './types/common';

export const KYC_STATUS = {
  INIT: 'INIT',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  DELETED: 'DELETED',
  REJECTED_RETRY: 'REJECTED_RETRY',
  REJECTED_FINAL: 'REJECTED_FINAL',
} as const;

export type KycStatusName = keyof typeof KYC_STATUS;

export type KycStatus = (typeof KYC_STATUS)[KycStatusName];

export interface LaunchpadUserInfo {
  id: string;
  type: string;
  attributes: {
    address: Address; // This field must be filled due to account in launchpad CAN NOT be without EVM Wallet address
    kycStatus: KycStatus | undefined;
    sqrpBalance: number;
  };
}

export type Banner = {
  id: string;
  mobileImage: string;
  desktopImage: string;
  altText: string;
  link: string;
  position: number;
  group: 'MAIN_PAGE';
  isHidden: boolean;
};

export type BannerResponse = {
  id: string;
  type: 'banner';
  attributes: Banner;
};

export type Campaign = {
  id: string;
  bannerImage: string;
  logoImage: string;
  title: string;
  status: string;
  link: string;
  prizePool: number;
  altText: string;
  buttonLabel: string;
  guaranteedAllocation: number;
  totalTokens: number;
  totalPrice: number;
  group: 'MAIN_PAGE';
  isHidden: boolean;
};

export type CampaignResponse = {
  id: string;
  type: 'campaign';
  attributes: Campaign;
};

export const getLaunchpadCurrentUser = async (options?: Options) => {
  const { data } = await Api.get('launchpad/users/me', options).json<{ data: LaunchpadUserInfo }>();
  return data;
};

export interface GetKYCAccessTokenResponse {
  token: string;
}

export const getKYCAccessToken = (
  options: ApiOptions<{
    json: {
      sourceId?: string;
    };
  }>,
) => {
  return Api.post('v2/magicid/user/kyc/token', options).json<GetKYCAccessTokenResponse>();
};

export interface GetKYCBabt {
  hasBABT: boolean;
}

export const getKYCBabt = () => {
  return Api.post('v2/magicid/user/kyc/babt').json<GetKYCBabt>();
};

export const subscribeToLaunchpadNewsletter = (
  options: ApiOptions<{
    json: {
      email: string;
    };
  }>,
) => {
  return subscribeToMagicIdNewsletter({
    ...options,
    json: {
      ...options.json,
      group: 'LAUNCHPAD',
    },
  });
};

export const PORTFOLIO_POOL_PROJECT_CLAIMING_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
} as const;

type PortfolioPoolProjectClaimingTypeName = keyof typeof PORTFOLIO_POOL_PROJECT_CLAIMING_TYPE;
type PortfolioPoolProjectClaimingType =
  (typeof PORTFOLIO_POOL_PROJECT_CLAIMING_TYPE)[PortfolioPoolProjectClaimingTypeName];

interface PortfolioPoolProject {
  name: string;
  title: string;
  meta: string;
  description: string;
  logoUrl: string;
  ticker: string;
  tags: string[];
  startDate: string;
  endDate: string;
  claimingStartDate: string;
  estimatedListingDate: string;
  tokenSupply: number;
  projectValuation: number;
  archived: boolean;
  isComingSoon: boolean;
  claimingBlockchain: string;
  claimingBlockchainId: WagmiConfigChain['id'];
  claimingType: PortfolioPoolProjectClaimingType;
  claimingInfo: any;
  bannerUrls: any;
  bannerSmallUrl: string;
  bannerUrl: string;
  links: { link: string; type: string; title: string }[];
  slides: any;
  highlights: { title: string; description: string }[];
  vestings: {
    tba: boolean;
    startTitle: string;
    startPercent: number;
    vestingPercent: number;
    startDescription: string;
    vestingStartDate: string;
    vestingDescription: string;
  };
  slug: string;
  restrictedCountries: string[];
  id: string;
}

export interface DeserializedSelfPool {
  id: string;
  price: number;
  name: string;
  type: LaunchpadSalePoolType;
  goal: number;
  paymentsReceived: number;
  announcement: string | null;
  participantCount: number;
  minAllocation: number;
  maxAllocation: number;
  startDate: string;
  endDate: string;
  externalLink: string | null;
  depositContractAddress: Address;
  depositTokenPrice: number;
  depositTokenTicker: string;
  claimingStartDate: string;
  claimingEndDate: string | null;
  claimContractAddress: Address | null;
  status: LaunchpadSalePoolStatus;
  meta: {
    whiteListExists: boolean;
    whiteListContainsUser: boolean;
  };
  /**
   * some times it can be null because of misconfiguration in cms
   * we need to handle this case to prevent breaking ui
   */
  project: PortfolioPoolProject | null;
  whiteList: {
    id: string;
    criteriaCheckStrategy: LaunchpadSalePoolWhitelistStrategy;
    allocationSize: number;
    startDate: string;
    endDate: string;
  } | null;
  refundDate: string | null;
  refund?: boolean;
}

export interface DeserializedSelfWhitelistPoolJoiningCriteriaResult {
  verified: boolean;
  whiteListJoiningCriteria: {
    id: string;
    title: string;
    description: string;
    settings: Record<string, string>;
  };
}

// NOTE: max 20 or back will return error
// NOTE: need 5, but made 6 to load 1 in advance to show see more button correctly
export const POOLS_DEFAULT_PAGE_LIMIT = 6;

export const getSelfActiveWhitelistingPools = async (
  options?: ApiOptions<{ searchParams?: PaginationSearchParams }>,
): Promise<DeserializedSelfPool[]> => {
  const optionsWithDefaults: typeof options = {
    ...options,
    searchParams: {
      ...options?.searchParams,
      limit: POOLS_DEFAULT_PAGE_LIMIT,
    },
  };

  const data = await Api.get(
    'launchpad/users/me/active-whitelisting-pools',
    optionsWithDefaults,
  ).json<any>();

  return deserializeJsonApi<DeserializedSelfPool[]>(data);
};

export const getSelfActiveSalesPools = async (
  options?: ApiOptions<{ searchParams?: PaginationSearchParams }>,
): Promise<DeserializedSelfPool[]> => {
  const optionsWithDefaults: typeof options = {
    ...options,
    searchParams: {
      ...options?.searchParams,
      limit: POOLS_DEFAULT_PAGE_LIMIT,
    },
  };

  const data = await Api.get(
    'launchpad/users/me/active-sales-pools',
    optionsWithDefaults,
  ).json<any>();

  const deserialized = await deserializeJsonApi<DeserializedSelfPool[]>(data);

  return deserialized;
};

export const getSelfAllocations = async (
  options?: Options,
): Promise<DeserializedSelfAllocations> => {
  const data = await Api.get('launchpad/users/me/allocations', options).json();

  const deserialized = await deserializeJsonApi<DeserializedSelfAllocations>(data);

  return deserialized;
};

export const getSelfClaimablePools = async (
  options?: ApiOptions<{ searchParams?: PaginationSearchParams }>,
): Promise<DeserializedSelfPool[]> => {
  const optionsWithDefaults: typeof options = {
    ...options,
    searchParams: {
      ...options?.searchParams,
      limit: POOLS_DEFAULT_PAGE_LIMIT,
    },
  };

  const data = await Api.get('launchpad/users/me/claimable-pools', optionsWithDefaults).json<any>();

  const deserialized = await deserializeJsonApi<DeserializedSelfPool[]>(data);

  return deserialized;
};

export const getSelfClosedPools = async (
  options?: ApiOptions<{ searchParams?: PaginationSearchParams }>,
): Promise<DeserializedSelfPool[]> => {
  const optionsWithDefaults: typeof options = {
    ...options,
    searchParams: {
      ...options?.searchParams,
      limit: POOLS_DEFAULT_PAGE_LIMIT,
    },
  };
  const data = await Api.get('launchpad/users/me/closed-pools', optionsWithDefaults).json<any>();

  const deserialized = await deserializeJsonApi<DeserializedSelfPool[]>(data);

  return deserialized;
};

export const getSelfWhitelistPoolJoiningCriteriaResults = async (
  poolId: string,
  options?: ApiOptions,
): Promise<{
  criteriaResults: DeserializedSelfWhitelistPoolJoiningCriteriaResult[];
  meta: {
    participationVerified: boolean;
  };
}> => {
  const data = await Api.get(
    `launchpad/pools/${poolId}/white-list/joining-criteria`,
    options,
  ).json<{ meta: { participationVerified: boolean } }>();

  const deserialized = await deserializeJsonApi<
    DeserializedSelfWhitelistPoolJoiningCriteriaResult[]
  >(data);

  return {
    criteriaResults: deserialized,
    meta: data.meta,
  };
};

export const verifySelfPoolWhitelist = async (
  poolId: string,
  options?: ApiOptions,
): Promise<{
  criteriaResults: DeserializedSelfWhitelistPoolJoiningCriteriaResult[];
  meta: {
    participationVerified: boolean;
  };
}> => {
  const data = await Api.post(`launchpad/pools/${poolId}/white-list/participants`, options).json<{
    meta: { participationVerified: boolean };
  }>();

  const deserialized = await deserializeJsonApi<
    DeserializedSelfWhitelistPoolJoiningCriteriaResult[]
  >(data);

  return {
    criteriaResults: deserialized,
    meta: data.meta,
  };
};

export const getBanners = async () => {
  const data = await Api.get('launchpad/banners').json<JsonApiObject>();

  return deserializeJsonApi<Banner[]>(data);
};

export const getCampaignsProjects = async () => {
  const data = await Api.get('launchpad/campaigns').json<JsonApiObject>();

  return deserializeJsonApi<Campaign[]>(data);
};
