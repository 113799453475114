import { notFound } from 'next/navigation';

import ky, { HTTPError, NormalizedOptions } from 'ky';

import getApiHost from '@shared/helpers/getApiHost';

import { getApiError } from '../helpers/api';
import { handleApiError, isNeedRedirect } from '../helpers/apiHandler';

export type { ApiOptions } from '../helpers/api';

const host: string = getApiHost();
const Api = ky.extend({
  prefixUrl: host,
  credentials: 'include',
  timeout: 30000,
  cache: 'no-store',
  hooks: {
    beforeError: [
      async (error: HTTPError & { code?: number; type?: string; data?: any }) => {
        const apiError = await getApiError(error);

        if (typeof window !== 'undefined') {
          handleApiError(error, apiError);
        }

        if (isNeedRedirect(error)) {
          notFound();
        }

        error.code = apiError.code;
        error.message = apiError.message;
        error.type = apiError.type;
        error.data = apiError.data;

        if (typeof window === 'undefined') {
          console.error(`API Error: code(${error.code}), url(${error.response.url})`);
        }

        return error;
      },
    ],
    beforeRequest: [
      (request) => {
        const version = typeof process !== 'undefined' ? process.env.NEXT_PUBLIC_STORE_VERSION : '';

        if (version) {
          request.headers.set('ms-version', version);
        }
      },
    ],
    afterResponse: [
      async (input: Request, options: NormalizedOptions, response: Response): Promise<Response> => {
        const contentType: string = response.headers.get('content-type') || '';

        if (contentType.includes('text/plain')) {
          const text: string = await response.text();

          return new Response(JSON.stringify({ data: text }), {
            status: response.status,
            statusText: response.statusText,
          });
        }

        return response;
      },
    ],
  },
  retry: 0,
});

export default Api;
