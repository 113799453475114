import { QueryClient, useQuery } from '@tanstack/react-query';
import { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import { Options, SearchParamsOption } from 'ky';

import { fetchCategories, getCurrentUserApps } from '@api';

import { AUTH_USER_QUERY_KEY } from './keys';

const myAppsQueryKeys = {
  myApps: (searchParams?: SearchParamsOption) => [AUTH_USER_QUERY_KEY, 'myApps', searchParams],
  categories: ['categories'],
};

export const prefetchMyApps = (clientQuery: QueryClient, options: Options) => {
  return clientQuery.prefetchQuery({
    queryKey: myAppsQueryKeys.myApps(),
    queryFn: () => getCurrentUserApps(options),
  });
};

export const prefetchCategoriesQuery = (clientQuery: QueryClient, options?: Options) => {
  return clientQuery.prefetchQuery({
    queryKey: myAppsQueryKeys.categories,
    queryFn: () => fetchCategories(options),
  });
};

export const useMyApps = (
  searchParams: SearchParamsOption,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getCurrentUserApps>>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'placeholderData'
  >,
) => {
  return useQuery({
    queryKey: myAppsQueryKeys.myApps(searchParams),
    queryFn: () => getCurrentUserApps({ searchParams }),
    ...options,
  });
};

export const useCategoriesQuery = (searchParams?: SearchParamsOption) => {
  return useQuery({
    queryKey: myAppsQueryKeys.categories,
    queryFn: () => fetchCategories({ searchParams }),
  });
};
