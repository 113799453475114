import { Options } from 'ky';

import Api, { ApiOptions } from './ApiProvider';
import { GetPerksListSearchParams, PerksList } from './types';

export const GET_PERKS_LIST_DEFAULT_LIMIT = 12;

export const getPerksList = (options?: ApiOptions<{ searchParams?: GetPerksListSearchParams }>) => {
  const optionsWithDefaults: Options = {
    cache: 'no-store',
    ...options,
    searchParams: {
      limit: GET_PERKS_LIST_DEFAULT_LIMIT,
      ...options?.searchParams,
    },
  };

  return Api.get('v1/crm/store/perks', optionsWithDefaults).json<PerksList>();
};
