import { useClientFeatureTogglesQuery, useFeatureTogglesQuery } from '@query';

import { NormalizedFeatureToggles } from '@api';

const defaultFeatureToggles: NormalizedFeatureToggles = {};

export const useFeatureToggles = () => {
  const { data = defaultFeatureToggles } = useFeatureTogglesQuery();

  return data;
};

export const useClientFeatureToggles = () => {
  const { data = defaultFeatureToggles } = useClientFeatureTogglesQuery();

  return data;
};
